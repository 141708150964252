import React from 'react';
import './App.css';
import * as datefns from "date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line, Chart } from 'react-chartjs-2';


interface PumpItem {
  Timestamp: Date;
  DeviceValue: string;
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export interface PumpOccurence {
  Id: string;
  TimeStarted: Date;
  TimeEnded: Date;
  EmptyInSeconds: number;
  FillTimeInSeconds: number;
  StartPct: number;
  TopPct: number;
  EndPct: number;
}


interface Statistics {
  countsincedate: number,
  avgfilltimesincedate: number,
  runslasthour: number,
  runsalltime: number,
  currentlevel: number,
  lastseen: string,
  avgfilltimealltime: number,
  avgfilltimehour: number,
  lastrun: string
}

interface PumpReport {
  occurences: Array<PumpOccurence>;
  stats: Statistics;
}


interface State {
  report: Report,
  stats: PumpReport,
  radon:number


}

interface Report {
  Items: Array<ReportItem>
}

interface ReportItemValue {
  Value: number;
  Timestamp: Date;
}

interface ReportItem {
  TimeFrom: Date;
  TimeTo: Date;
  Values: Array<ReportItemValue>
}

export class App extends React.Component<any, State>
{

  
  state = {
    stats: {} as PumpReport,
    report: {} as Report,
    radon:0
  }

  async componentDidMount() {
    
    var loc = (window.location.hostname === "localhost") ? "http://localhost:3000/online/data" : "https://stats.helgesmeby.net/data";
    var repUrl = (window.location.hostname === "localhost") ? "http://localhost:3000/online/stats" : "https://stats.helgesmeby.net/pumpdata/pump_data_report.json";
    var f = await fetch(loc);
    var txt = await f.json();
    var r = await fetch(repUrl);
    var js = await r.json();
    console.log("t", txt);
    this.setState({
      stats: txt.Pump as PumpReport,
      report: js as Report,
      radon:txt.Radon
    });
  }



  render(): React.ReactNode {

    if (!this.state.stats.stats)
      return <></>

    

      console.log("State", this.state);
    var s = datefns.intervalToDuration({start:0, end:this.state.stats.stats.avgfilltimehour*1000});
      return (
      <div className="body">
        <h1>Report</h1>
        
        <hr />
        <div>
          Level: {100 - parseInt(this.state.stats.stats.currentlevel.toString())}%
        </div>
        <div>
          Last update: {datefns.format(new Date(this.state.stats.stats.lastseen), 'dd.MM.yyyy HH:mm:ss')} - {datefns.formatDistance(new Date(this.state.stats.stats.lastseen), new Date())} ago
        </div>
        <div>
          Last run: {datefns.format(new Date(this.state.stats.stats.lastrun), 'dd.MM.yyyy HH:mm:ss')} - {datefns.formatDistance(new Date(this.state.stats.stats.lastrun), new Date())} ago
        </div>
        <div>
          Avg. filltime past hour: {s.hours}:{s.minutes}:{s.seconds}
        </div>
        <div>
          Number of runs this month: {this.state.stats.occurences.length}
        </div>
        <div>          
          Runs today: {this.state.stats.occurences.filter(k => datefns.isToday(new Date(k.TimeStarted))).length}
        </div>
        

        <h3>Radon</h3>
        {this.state.radon} bq/m3



        {/*<h2>Reports</h2>
          {this.state.report.Items.map((item) => {

            if (item.Values.length <= 0)
              return null;

            const data = {              
              labels: item.Values.map(k => datefns.format(new Date(k.Timestamp), 'HH:mm:ss')),              
              datasets: [
          {
            label: 'Dataset 1',
                    data: item.Values.map( k => k.Value),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  }]         
              };

          return <div>
            {datefns.format(new Date(item.TimeFrom), 'dd-MM-yyyy HH:mm')} - {datefns.format(new Date(item.TimeTo), 'HH:mm')} |
            {item.Values.length} values
            
              <ChartView data={data} />
                
          </div>
          })}
        </div>        
        </div>*/}
      </div>

    )
  }
}

interface Props {
  data: any;
}

interface ChartState {
  Expanded: boolean;
}
export class ChartView extends React.Component<Props, ChartState>
{
  state = {
    Expanded: false
  }
  render(): React.ReactNode {
    if (!this.state.Expanded)
      return <div style={{ display: "inline-block", marginLeft: "10px" }}>
        <button onClick={() => this.setState({ Expanded: !this.state.Expanded })} >Graph</button> </div>


    return (

      <div style={{ display: "block" }}>
        <Line data={this.props.data} />
      </div>
    )
  }

}

export default App;
